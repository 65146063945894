
//import * as Hammer from 'hammerjs';

console.log('HandlerTouch JS geladen');

document.addEventListener("DOMContentLoaded", function(event){
    

    var navSystems = document.getElementById('background-stage');
    var viewportWidth = window.innerWidth;
    

    // create a simple instance
    // by default, it only adds horizontal recognizers
    //var mc = new Hammer(myElement[0]);

    var nsh = new Hammer(navSystems);
    var clientXPosition;
    nsh.get("pan").set({ enable: true});
    nsh.on("panstart", function(e){
        clientXPosition =  parseInt(navSystems.offsetLeft);
        console.log('TOUCHPOINT X ----------------> ' + clientXPosition );
    })

    nsh.on("panleft", function(ev) {
        console.log('PAN-LEFT: ', ev.deltaX)
        
        if ( navSystems.offsetLeft > viewportWidth - parseInt(navSystems.offsetWidth) + 60 ){
            navSystems.style.left = clientXPosition + ev.deltaX  + 'px' ;
        }
    });

    nsh.on("panright", function(ev){
        console.log('PAN-RIGHT: ', ev.deltaX);

        if ( parseInt(navSystems.offsetLeft) < 0 ){
            navSystems.style.left = ev.deltaX + clientXPosition  + 'px' ;
        }
    });

});

